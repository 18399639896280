import ajax from "./agent.js";
import moment from "moment";
import React from "react";
import renderHTML from "react-render-html";
import { Loader } from "semantic-ui-react";
import { isValidSession, getAllSessionParameters, decodeIdToken } from "../components/Login/actions/session";
import config from 'config';
import { truncate } from 'lodash'
/**
 * currencyConversion(JSONBody, key, screen); used to covert 'INR' and 'USD' to their curreny symbol to be displayed on screen.
 * JSONBody => JSON object containing table data
 * key => JSON object key which referes curreny.
 * screen => conversions specific to UI. Need to pass screen name from UI and conversions specific to that UI will happen*/

function dataConversion(JSONBody, date_key, currency_key, screen) {
  let chkJSON = Array.isArray(JSONBody);
  //console.log(chkJSON)
  if (chkJSON) {
    for (var i = 0; i < JSONBody.length; i++) {
      if (date_key !== "") {
        var dateTime = moment
          .utc(JSONBody[i][date_key], "DD-MM-YYYY HH:mm:ss")
          .local()
          .format("YYYY-MM-DD HH:mm:ss");
        JSONBody[i][date_key] = dateTime;
      }
      //console.log(JSONBody[i][date_key])
      if (currency_key !== "") {
        if (JSONBody[i][currency_key] === "INR") {
          JSONBody[i][currency_key] = renderHTML("&#8377;");
        } else if (JSONBody[i][currency_key] === "USD") {
          JSONBody[i][currency_key] = renderHTML("&#36;");
        } else if (JSONBody[i][currency_key] === "GBP") {
          JSONBody[i][currency_key] = renderHTML("&#163;");
        } else if (JSONBody[i][currency_key] === "EUR") {
          JSONBody[i][currency_key] = renderHTML("&#8364");
        } else {
          JSONBody[i][currency_key] = renderHTML("&#36;");
        }
      }
      if (screen === "purchases") {
        JSONBody[i]["statusDesc"] = "";
        if (JSONBody[i].status === 10) {
          JSONBody[i].statusDesc = "EXECUTED";
        } else if (JSONBody[i].status === 20) {
          JSONBody[i].statusDesc = "SETTLED";
        } else if (JSONBody[i].status === 30) {
          JSONBody[i].statusDesc = "SETTLEMENT_FAILED";
        } else if (JSONBody[i].status === 40) {
          JSONBody[i].statusDesc = "DELIVERED";
        } else if (JSONBody[i].status === 60) {
          JSONBody[i].statusDesc = "TRADE_REVERSED";
        } else if (JSONBody[i].status === 70) {
          JSONBody[i].statusDesc = "ORDER_COMPLETED";
        }
      } else if (screen === "trade") {
        JSONBody[i]["tradeStatusDesc"] = "";
        if (JSONBody[i].tradeStatus === 10) {
          JSONBody[i].tradeStatusDesc = "EXECUTED";
        } else if (JSONBody[i].tradeStatus === 20) {
          JSONBody[i].tradeStatusDesc = "SETTLED";
        } else if (JSONBody[i].tradeStatus === 30) {
          JSONBody[i].tradeStatusDesc = "SETTLEMENT_FAILED";
        } else if (JSONBody[i].tradeStatus === 40) {
          JSONBody[i].tradeStatusDesc = "DELIVERED";
        } else if (JSONBody[i].tradeStatus === 60) {
          JSONBody[i].tradeStatusDesc = "TRADE_REVERSED";
        } else if (JSONBody[i].tradeStatus === 70) {
          JSONBody[i].tradeStatusDesc = "ORDER_COMPLETED";
        }
      } else if (screen === "order") {
        JSONBody[i]["finalOrderPrice"] = "";
        JSONBody[i].finalOrderPrice = JSONBody[i].orderNegoPrice;
        if (JSONBody[i].orderStatus === 40 && JSONBody[i].orderType === "Buy") {
          JSONBody[i].remarks = "AWAITING SELLER RESPONSE";
        } else if (
          JSONBody[i].orderStatus === 40 &&
          JSONBody[i].orderType === "Sell"
        ) {
          JSONBody[i].remarks = "AWAITING BUYER RESPONSE";
        } else if (
          JSONBody[i].orderStatus === 30 &&
          JSONBody[i].orderType === "Buy"
        ) {
          JSONBody[i].remarks = "ORDER PLACED";
        } else if (
          JSONBody[i].orderStatus === 50 &&
          JSONBody[i].orderType === "Buy"
        ) {
          JSONBody[i].remarks = "REJECTED BY SELLER";
        } else if (
          JSONBody[i].orderStatus === 30 &&
          JSONBody[i].orderType === "Sell"
        ) {
          JSONBody[i].remarks = "ORDER PLACED";
        } else if (JSONBody[i].orderStatus === 60) {
          JSONBody[i].remarks = "CANCELLED";
        } else if (JSONBody[i].orderStatus === 70) {
          JSONBody[i].remarks = "COMPLETED";
          JSONBody[i].finalOrderPrice = JSONBody[i].counterOrderPrice;
        } else if (JSONBody[i].orderStatus === 80) {
          JSONBody[i].remarks = "SYSTEM CANCELLED";
        } else if (JSONBody[i].orderStatus === 120) {
          JSONBody[i].remarks = "ORDER UNDER NEGOTIATION";
        }
      } else if (screen === "modifiedorder") {
        //generate the term statement
        if (JSONBody[i].isNegotiable === false) {
          JSONBody[i].newRemarks = "Non-Negotiable";
        } else if (
          JSONBody[i].isAdditional === false &&
          JSONBody[i].isAccepted === false
        ) {
          JSONBody[i].newRemarks = "Disagree";
        } else if (
          JSONBody[i].isAdditional === false &&
          JSONBody[i].isAccepted === true
        ) {
          JSONBody[i].newRemarks = "Agree";
        } else {
          JSONBody[i].newRemarks = "Negotiable";
        }
      } else if (screen === "modifiedbyotherparty") {
        if (JSONBody[i].isNegotiable === false) {
          JSONBody[i].newRemarks = "Non-Negotiable";
        } else if (JSONBody[i].isAccepted == false) {
          JSONBody[i].newRemarks = "Disagree";
        } else if (JSONBody[i].isAccepted == true) {
          JSONBody[i].newRemarks = "Agree";
        }
        /*else
                {
                JSONBody[i].newRemarks = 'Negotiable';
                }*/
      }
    }
  } else {
    if (date_key !== "") {
      var dateTime = moment
        .utc(JSONBody[date_key], "DD-MM-YYYY HH:mm:ss")
        .local()
        .format("YYYY-MM-DD HH:mm:ss");
      JSONBody[date_key] = dateTime;
    }
    //console.log(JSONBody[i][date_key])
    if (currency_key !== "") {
      if (JSONBody[currency_key] === "INR") {
        JSONBody[currency_key] = renderHTML("&#8377;");
      } else if (JSONBody[currency_key] === "USD") {
        JSONBody[currency_key] = renderHTML("&#36;");
      } else if (JSONBody[currency_key] === "GBP") {
        JSONBody[currency_key] = renderHTML("&#163;");
      } else if (JSONBody[currency_key] === "EUR") {
        JSONBody[currency_key] = renderHTML("&#8364");
      } else {
        JSONBody[currency_key] = renderHTML("&#36;");
      }
    }
  }
  return JSONBody;
}
function currencyConversion(context) {
  if (context.currency === "INR") {
    context.currency = renderHTML("&#8377;");
  } else if (context.currency === "USD") {
    context.currency = renderHTML("&#36;");
  }
}
function handleErrors(err) {
  let errorArray = {
    errFlag: "N",
    errMessage: [],
  };
  let messages = [];
  if (err && err.response && err.response.status === 400) {
    errorArray.errFlag = "Y";
    messages.push(
      "400 Bad Request. Server was unable to process the request sent by the client due to malformed request."
    );
  } else if (err && err.response && err.response.status === 401) {
    errorArray.errFlag = "Y";
    messages.push("401 Unauthorized.");
  } else if (err && err.response && err.response.status === 403) {
    errorArray.errFlag = "Y";
    messages.push(
      "403 Forbidden. The user is not allowed to perform the required action."
    );
  } else if (err && err.response.status === 404) {
    errorArray.errFlag = "Y";
    messages.push("404 Not Found. The requested resource is not found.");
  } else if (err && err.response.status === 408) {
    errorArray.errFlag = "Y";
    messages.push("408 Request Timeout.");
  } else if (err && err.response.status === 500) {
    errorArray.errFlag = "Y";
    messages.push("500 Internal Server Error.");
  } else if (err && err.response.status === 503) {
    errorArray.errFlag = "Y";
    messages.push(
      "503 Service Unavailable. The server is currently unavailable."
    );
  } else if (err && err.response.status === 504) {
    errorArray.errFlag = "Y";
    messages.push("504 Gateway Timeout.");
  } else if (err && err.response.status === 417) {
    errorArray.errFlag = "Y";
    messages.push(err.response.text);
  }
  messages.push(
    " Please try again after some time or contact your system administrator."
  );
  errorArray.errMessage = messages;
  return errorArray;
}
function handleResponse(response) {
  let message = [];
  let i = 0;
  //console.log(response)
  if (response && response.body.errors.length > 0) {
    response.body.errors.map((obj) => {
      message.push(obj.defaultMessage);
    });
  }
  return message;
}
function enterLoading(context) {
  context.setState({ loader: true });
}
function enterUnLoading(context) {
  context.setState({ loader: false });
}
function ShowLoading(context) {
  if (context.state.loader) {
    return (
      <div>
        <Loader active inline="centered">
          Loading
        </Loader>
      </div>
    );
  } else return null;
}
function ShowLoadingV2(context) {
  if (context.state.loader) {
    return (
      <div className='flex flexJustifyCenter flexAlignCenter' style={{ height: '80vh' }}>
        <Loader active inline="centered">
          Loading
        </Loader>
      </div>
    );
  } else return null;
}

function redirectToProductDetailPage(e, redirectTo, context, isReview) {
  context.props.history.push(
    "/" + redirectTo + "/" + e.target.id.toString() + "/" + isReview
  );
  event.preventDefault();
}
function dateFormated(date) {
  var date1 = new Date(date);
  var month = ("0" + (date1.getMonth() + 1)).slice(-2);
  var day = ("0" + date1.getDate()).slice(-2);
  var year = date1.getFullYear();
  var hours = date1.getHours();
  if (hours < 10) {
    hours = "0" + hours;
  }
  var mins = date1.getMinutes();
  if (mins < 10) {
    mins = "0" + mins;
  }
  var newDate =
    year + "-" + month + "-" + day + " " + hours + ":" + mins + ":00";
  return newDate;
}
/**a basic modal will appear on fullscreen mode showing message passed in the parameter 'message' */
function loadingModalLoader(context, message) {
  context.setState({ loading: true, loaderMessage: message });
}
function unLoadingModalLoader(context) {
  context.setState({ loading: false, loaderMessage: "" });
}

/**loadDisplayMessage() is used to tell the displayMessage component the type of message from out of following types.
 * success
 * error
 * info
 * warning
 */
function loadDisplayMessage(context, type, header, messageArray) {
  context.setState({
    messageType: type,
    messageHeader: header,
    message: messageArray,
  });
}
function unloadDisplayMessage(context) {
  context.setState({ messageHeader: "", message: [] });
}
function setPageRequirements(context, page, pageSize) {
  context.setState({ isProvided: true });
  context.setState({ page: page });
  context.setState({ pageSize: pageSize });
  let startingIndex = (page - 1) * pageSize;
  return startingIndex;
}

function setData(context, err, response) {
  if (!err && response) {
    context.setState({
      productData: response.body.hits.hits,
      productData1: response.body.hits.hits,
      dataLength: response.body.hits.total,
    });
    context.setState({ dataLength: response.body.hits.hits.length });
  } /*
   else
       self.setState({errorResponse:temp});*/
}
function queryElastic(key, context, startingIndex, pageSize) {
  var temp = ajax.ElasticDataProduct;
  var func = temp[key];
  func(startingIndex, pageSize).end((err, response) => {
    if (!err && response) {
      //console.log(response)
      return response.body.hits.hits;
      context.setState({ productData: response.body.hits.hits });
      context.setState({ dataLength: response.body.hits.hits.length });
    }
  });
}
function wordWrap(text, width) {
  var re = new RegExp("([\\w\\s]{" + (width - 2) + ",}?\\w)\\s?\\b", "g");
  return text.replace(re, "$1\n");
}
/**
 * allroles - parameter contains all roles associatd with the user in wso2.
 * Function then verifies specific user roles like organization admin, platform admin etc.
 */
function processUserRoles(allroles) {
  let roles = [];
  for (var i = 0; i < allroles.length; i++) {
    var expr = allroles[i];
    switch (expr) {
      case "ROLE_ORG_ADMIN":
        roles.push("ROLE_ORG_ADMIN");
        break;
      case "ROLE_ORG_USER":
        roles.push("ROLE_ORG_USER");
        break;
      case "ROLE_PF_ADMIN":
        roles.push("ROLE_PF_ADMIN");
        break;
    }
  }
  return roles;
}
/**
 * This function determines if the user is an organization admin depending on
 * wheather it contains organization admin role among asigned set of roles
 * @param {*} allroles
 */
function isOrgAdmin(allroles) {
  for (var i = 0; i < allroles.length; i++) {
    var expr = allroles[i];
    switch (expr) {
      case "ROLE_ORG_ADMIN":
        return true;
    }
  }
  return false;
}

function lengthChecking(e, msgDisplayArea, displayMsg) {
  if (e.target.value.length === 0) {
    e.target.style.borderColor = "red";
    msgDisplayArea.style.color = "red";
    msgDisplayArea.style.fontSize = "13px";
    msgDisplayArea.style.fontWeight = "bold";
    msgDisplayArea.style.fontFamily = "Verdana";
    msgDisplayArea.innerHTML = displayMsg;
  } else {
    msgDisplayArea.innerHTML = "";
    e.target.style.borderColor = "#DEDEDF";
  }
}
function displayInlineErrorMessage(e, msgDisplayArea, displayMsg) {
  e.target.value = null;
  e.target.style.borderColor = "red";
  msgDisplayArea.style.color = "red";
  msgDisplayArea.style.fontSize = "13px";
  msgDisplayArea.style.fontWeight = "bold";
  msgDisplayArea.style.fontFamily = "Verdana";
  msgDisplayArea.innerHTML = displayMsg;
}
function removeInlineErrorMessage(e, msgDisplayArea) {
  msgDisplayArea.innerHTML = "";
  e.target.style.borderColor = "#DEDEDF";
}
/**
 * This function will return array of distinct elements in a json for a column
 * @param {*} data - the json
 * @param {*} column - one column of the json
 */
function findDistinctElements(data, column) {
  if (data) {
    let filters = [],
      filter_column = column;
    let rcdset = new Set(); //adding elements to a set will remove duplicates.
    for (var i = 0; i < data.length; i++) {
      let rcd = data[i][filter_column];
      rcdset.add(rcd);
    }
    var temparr = Array.from(rcdset);

    for (let item of temparr) {
      if (item !== null) {
        filters.push({
          text: item,
          value: item,
        });
      }
    }
    return filters;
  }
}
function validateEmailId(value) {
  let pattern = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  return pattern.test(value);
}
function validateWebSite(value) {
  let pattern = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
  return pattern.test(value);
}

function catsubcatFilter(catList, self) {
  let arr = [];
  catList
    .sort((a, b) => a.value - b.value)
    .map((item) => {
      let subcatList = self.props.fetchSubCategoryList(item.value);
      subcatList.map((data) => {
        arr.push({
          text: `${item.text} (${data.text})`,
          value: `${item.text} (${data.text})`,
        });
      });
    });
  return arr;
}

function toUTC(dt) {
  return moment(dt).format("DD-MM-YYYY") + " 00:00:00.000Z";
}
function toDateWithoutTime(dt) {
  return moment
    .utc(dt, "DD-MM-YYYY HH:mm:ss")
    .local()
    .format("YYYY-MM-DD");
}

function fetchUserId() {
  const session = getAllSessionParameters();
  if (config.ENVIRONMENT) return config.DEV_USERNAME;
  if (session && session.ID_TOKEN) {
    let decodeIdToken = JSON.parse(atob(session.ID_TOKEN.split(".")[1]));
    return decodeIdToken.sub;
  }
}
function fetchUserBpid() {
  const session = getAllSessionParameters();
  if (config.ENVIRONMENT) return config.DEV_BPID;
  if (session && session.ID_TOKEN) {
    let decodeIdToken = JSON.parse(atob(session.ID_TOKEN.split(".")[1]));
    // console.log(decodeIdToken)
    return decodeIdToken.BPID;
  }
}
function fetchUserFirstName() {
  const session = getAllSessionParameters();
  if (config.ENVIRONMENT) return config.DEV_USER;
  if (session && session.ID_TOKEN) {
    let decodeIdToken = JSON.parse(atob(session.ID_TOKEN.split(".")[1]));
    return decodeIdToken.given_name;
  }
}

function elipses(str, count) {
  return truncate(str, { 'length': count, 'omission': '...' })
}

function formatDate(date) {
  return moment.utc(date).local().format("YYYY-MM-DD HH:mm")
  // return moment(date).format("YYYY-MM-DD HH:mm")
}
function timeAgo(date) {
  let newDate = formatDate(date)
  return moment(newDate).fromNow()
}


function resolveDcnStatus(parentDcnId, dcnStatus) {
  if(parentDcnId && dcnStatus==='Draft'){
    return 'Variation'
  }
  if(!parentDcnId && dcnStatus==='Draft'){
    return 'Draft'
  }

  return null
}

const isUrlValid = (url) => {
  if (/^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(url))
      return true;
  else {            
      return false;
  }  
}
function isEmailValid(email) {
    // var re = /\S+@\S+\.\S+/;
    let re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    return re.test(email);
}

function getAntdTablePageOptions(dataLength) {
  let pageSizeOptions = ['10', '20', '40', '50', '100', '500', '1000']
  return pageSizeOptions.filter((item, key) => (key > 0 ? (dataLength >= Number.parseInt(pageSizeOptions[key - 1])) : true) || dataLength == Number.parseInt(pageSizeOptions[key]))
}

export default {
  dataConversion,
  handleErrors,
  enterLoading,
  enterUnLoading,
  ShowLoading,
  ShowLoadingV2,
  redirectToProductDetailPage,
  dateFormated,
  loadingModalLoader,
  unLoadingModalLoader,
  currencyConversion,
  setPageRequirements,
  setData,
  loadDisplayMessage,
  unloadDisplayMessage,
  wordWrap,
  queryElastic,
  processUserRoles,
  isOrgAdmin,
  lengthChecking,
  displayInlineErrorMessage,
  removeInlineErrorMessage,
  findDistinctElements,
  validateEmailId,
  validateWebSite,
  handleResponse,
  catsubcatFilter,
  toUTC,
  toDateWithoutTime, 
  fetchUserId,
  fetchUserBpid,
  fetchUserFirstName,
  elipses,
  timeAgo,
  formatDate,
  resolveDcnStatus,
  isUrlValid,
  isEmailValid,
  getAntdTablePageOptions
};
