import React, { Component, useRef, useEffect } from 'react'
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import * as A from 'leaflet';
import 'proj4leaflet';
// import icon from 'leaflet/dist/images/marker-icon.png';
// import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { MapContainer, TileLayer, Marker, Popup, WMSTileLayer, useMapEvent, useMap } from 'react-leaflet'
import { Link } from 'react-router-dom';

// L.Icon.Default.imagePath='leaflet_images/';

function SetViewOnClick({ animateRef }) {
    const map = useMapEvent('click', (e) => {
        map.setView(e.latlng, map.getZoom(), {
            animate: animateRef.current || false,
        })
    })

    return null
}
function FitBnds({ data, selectedPoint, crs, center }) {
    const map = useMap(false)

    useEffect(() => {
        let bounds = data && data.length > 0 && data.map((item, key) => {
            let latLngObj = (item.x && item.y) ? crs.projection.unproject(L.point(item.x, item.y)) : null
            return (latLngObj ? [latLngObj.lat, latLngObj.lng] : center)
        })
        data && data.length > 0 && map.fitBounds(bounds);
    }, [data, selectedPoint])

    return null
}

const HeatMap = (props) => {
    const animateRef = useRef(false)


    const getIcon = (className, label) => {
        // return L.icon({ iconUrl: "../../../images/map_pin.png", iconSize: [32, 32], iconAnchor: [16, 32], className });
        return L.divIcon({
            iconSize: [32, 32], iconAnchor: [16, 32],
            html: `<div class="${className}">
                <img src="../../../images/map_pin.png"/>
                    <div class="text-center colWhite bgGrey20 border-rad mt-5px" style="opacity: .8;">${label}<div>
            </div>`,
            className: '',
        })
    }

    const crs = new A.Proj.CRS(
        'EPSG:27700',
        '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +datum=OSGB36 +units=m +no_defs',
        {
            resolutions: [1600, 800, 400, 200, 100, 50, 25, 10, 5, 1, 0.5, 0.25, 0.125, 0.09375]
        }
    )

    return (
        <div>
            <MapContainer
                center={props.center}
                zoom={props.zoom}
                maxZoom={9}
                style={{ height: props.height, width: props.width }}
                scrollWheelZoom={props.scrollWheelZoom}
                crs={crs}
                trackResize={true}
            // whenCreated={map => setState({ map })}
            >
                <WMSTileLayer
                    tileSize={256}
                    // zoomOffset={0}                        
                    // detectRetina={false}                        
                    layers='viaEuropa_m0102'
                    transparent={true}
                    opacity={1}
                    format={'image/png'}
                    attribution='Contains OS data © Crown copyright and database right 2021. OS licence number 100050367.'
                    url="https://map.publicsectormapping.gov.scot/osmao-impse-rv023-65788/service"
                />
                {
                    props.data && props.data.map((item, key) => {
                        let latLngObj = (item.x && item.y) ? crs.projection.unproject(L.point(item.x, item.y)) : null
                        // console.log(latLngObj)
                        let label = item.dcnList?.length > 1 ? item.dcnList?.length : ''
                        return (
                            latLngObj &&
                            <Marker
                                key={key}
                                position={[latLngObj.lat, latLngObj.lng]}
                                icon={item.dcnList[0].dcnStatus === 'Active' ? getIcon('map-pin map-pin-active', label) : getIcon('map-pin map-pin-inactive', label)}
                            >
                                <Popup>
                                    <div style={{ maxHeight: '240px', overflow: 'auto', paddingRight: '10px' }}>
                                        {item.dcnList?.length > 1 && <div className='textMedium textBold'><strong>Showing {item.dcnList.length || 0} DCNs</strong></div>}
                                        {item.dcnList?.map(dcn =>
                                            <div key={dcn.dcnId} className='border-b py-1'>
                                                <div className='textNormal colPrimary'><strong>{dcn.dcnReferenceNo}</strong></div>
                                                <div className='textSmall'><strong>Dog Owner </strong> {dcn.ppForename} {dcn.ppSurname}</div>
                                                <div className='textSmall'><strong>Status </strong> {dcn.dcnStatus}</div>
                                                <div className='textSmall'><strong>Address </strong> {dcn.ppAddressLine1} {dcn.ppAddressLine2} {dcn.ppCity} {dcn.ppCity} {dcn.ppPostCode}</div>
                                                <div className='textSmall'><strong>UPRN </strong> {dcn.ppUprn}</div>
                                                <Link className='customLink' to={`/dashboard/dogControlNotices/dcnsdetailpage/${dcn.dcnId}`}>View DCN</Link>
                                            </div>
                                        )}
                                    </div>
                                </Popup>

                            </Marker>
                        )

                    })
                }
                <SetViewOnClick animateRef={animateRef} />
                <FitBnds data={props.data} center={props.center} selectedPoint={props.selectedPoint} crs={crs} />
            </MapContainer>
        </div>
    )
}


export default HeatMap


