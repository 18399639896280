import React, { Component } from 'react'
import { Image } from 'semantic-ui-react'
import ajax from '../agent.js'
import utility from '../utility.js'
export class BreedImage extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }
    componentDidMount() {
        this.fetchDogBreedImg()
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.imageName !== this.props.imageName) {
            this.fetchDogBreedImg()
        }
    }

    fetchDogBreedImg = () => {
        ajax.dogbreed.get(this.props.imageName).then((resolve) => {
           var headers = resolve.headers;
           var blob = new Blob([resolve.xhr.response], { type: headers['content-type'] });    //{type:headers['content-type']}
  
           this.setState({
              breedImgDownloadedLink: window.URL.createObjectURL(blob)
           })
        })
     }


    render() {
        return (
            <>
                {this.state.breedImgDownloadedLink && <Image src={`${this.state.breedImgDownloadedLink}`} alt="Dog Breed" style={this.props.style} />}
            </>
        )
    }
}

export default BreedImage
