import React from 'react';
import { Message } from 'semantic-ui-react';

class ErrorMessage extends React.Component {
    constructor(props){
        super(props);
        this.ShowMessage = this.ShowMessage.bind(this);
        //this.textInput = React.createRef();
        this.state={errFlg:'N'};
    }
    ShowMessage(props){
        if(props.param.errFlag !== undefined) {
            if(props.param.errFlag === "Y") {
                return (
                    <Message color="red" autoFocus > {props.param.errMessage} </Message>
                )
            }
            else return null;
        }
        else return null;
    }
    componentWillReceiveProps(nextProps){
        this.setState({errFlg:nextProps.showServerError.errFlag});
    }
    componentDidUpdate(){
        //console.log(this.state.errFlg)
        if(this.state.errFlg !==undefined && this.state.errFlg==='Y') {
            let objControl=document.getElementById("messageID");
            objControl.scrollIntoView();
        }
    }
    render(){
        self = this;
        //console.log("Inside error handling")
        return (
            <div id="messageID">
            <self.ShowMessage param={self.props.showServerError}/>
            </div>
        )
    }
}

export default ErrorMessage;
