import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Segment, Divider, Button, Icon, Grid, Table, Header, Modal, Message } from 'semantic-ui-react';
import commonCss from '../../components/css/commonCss.js';
import ajax from '../../commons/agent.js';
import utility from '../../commons/utility.js';
import DisplayMessage from '../displayMessage.jsx';

class FileUpload extends React.Component {
   constructor(props) {
      super(props);

      this.state = {
         filelist: [], messageType: "error", messageHeader: "", message: [],
         viewmode: props.viewmode,
         referenceCode: props.referenceCode,
         bpid: null, dataProductCode: null, uploaded: false, fetchlist: [],
         loader: false,
         openModalConfirm: false, deleteFile: null
      }

      this.handleClick = this.handleClick.bind(this);
   }
   componentDidMount() {
      // self.reload()
   }
   componentDidUpdate(prevProps, prevState) {
      var self = this;
      if (prevProps.referenceCode !== this.props.referenceCode) {
         this.setState({ referenceCode: this.props.referenceCode }, () => self.reload())
      }
      if (prevProps.viewmode !== this.props.viewmode) {
         this.setState({ viewmode: this.props.viewmode })
      }
   }

   reload = () => {
      // let self = this
      // if (self.state.referenceCode != null) {
      //    //console.log(self.state.bpid)
      //    utility.enterLoading(self);
      //    ajax.DCNService.listAllFiles(self.state.referenceCode).end((err, response) => {
      //       utility.enterUnLoading(self);
      //       let errJson = utility.handleErrors(err);
      //       if (!err && response) {
      //          //console.log(response.body)
      //          self.setState({ fetchlist: response.body });
      //          self.props.setDocListCount(self.state.fetchlist.length)
      //       } else {
      //          utility.loadDisplayMessage(self, "error", "Failure.", errJson.errMessage);
      //       }
      //    })
      // }
   }

   handleClick() {
      // this.props.history.push(`/dcnUser/dcnsdetailpage/${this.state.referenceCode}`);
   }

   onChangeFileHandler = (e) => {
      e.preventDefault();
      let self = this
      if(e.target.files[0].type === 'application/pdf'){
         self.setState({ filelist: [...e.target.files], loading: false }, () => this.uploadFiles());
      }
      else{
         utility.loadDisplayMessage(self, "warning", "WARNING", ['Only PDF files are allowed.']);
      }
   }

   uploadFiles = () => {
      let self = this
      let files = self.state.filelist;
      if (files != null && self.state.referenceCode != null) {

         //upload limit is capped to 1 files.
         let limit = 1;
         let numUploaded = self.state.fetchlist.length;

         if (files.length > (limit - numUploaded)) {
            utility.loadDisplayMessage(self, "warning", "WARNING", ['You are not allowed to upload more than 5 files.']);
            return;
         }
         let formData = new FormData();
         formData.append("referenceCode", self.state.referenceCode)
         formData.append("fileType", self.props.type)
         for (let i = 0; i < files.length; i++) formData.append("files", files[i])

         self.setState({ isUploading: true })
         ajax.DCNService.uploadfiles(formData).end((err, response) => {
            self.setState({ isUploading: false })
            let errJson = utility.handleErrors(err);
            if (!err && response) {
               let msg = [];
               if (response.status == "200") {
                  self.reload();
                  self.setState({ uploaded: true, filelist: [] })
                  this.props.onFileUploadSuccess()
               } else {
                  msg.push(response.text);
                  utility.loadDisplayMessage(self, "info", "Information.", msg);
               }
            } else {
               utility.loadDisplayMessage(self, "error", "Failure.", errJson.errMessage);
            }
         })
      }
   }

   downloadFile = (e) => {
      let self = this
      let filename = e.target.id;
      if (self.state.referenceCode != null && filename != null) {
         var promise = new Promise((resolve, reject) => {
            ajax.DCNService.downloadFile(self.state.referenceCode, filename).end(async (err, response) => {
               let errJson = utility.handleErrors(err);
               if (!err && response) {
                  let msg = [];
                  resolve(response);
               } else {
                  utility.loadDisplayMessage(self, "error", "Failure.", errJson.errMessage);
               }
            })
         }).then((resolve) => {
            var headers = resolve.headers;
            var blob = new Blob([resolve.xhr.response], { type: headers['content-type'] });    //{type:headers['content-type']}
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            link.remove();
            link.addEventListener("focus", w => { window.URL.revokeObjectURL(blob) });

         }).then((reject) => {
            //utility.loadDisplayMessage(self, "error", "Failure.", errJson.errMessage);
         }).finally(() => {
         })
      }
   }


   handleReset = () => {
      this.setState({ uploaded: false, filelist: null });
   }

   render() {
      let self = this;

      return (
         <Segment loading={this.state.isUploading} textAlign='center'>
            <DisplayMessage context={this} messageType={this.state.messageType} messageHeader={this.state.messageHeader} message={this.state.message} />

            {
               this.state.viewmode == "" || this.state.viewmode == "false" ?
                  <div>
                     <div className='textBold colPrimary textNormal mb-5px'>{this.props.title}</div>

                     {!this.state.referenceCode &&
                        <Header as='h5' color='orange' className='my-5px'>
                           <Header.Content>Save as draft at least once to be able to upload.</Header.Content>
                        </Header>
                     }

                     <Grid>
                        <Grid.Column width={16} textAlign='center'>                         

                           <div>
                              {
                                 (this.state.referenceCode == null) ? //new mode when refernce code not generated, component disabled
                                    <div>
                                       {/* <input type="file" disabled /> */}
                                       <label class="customFileInput">
                                          <Header className='m-0' color='grey' as='h5' icon disabled>
                                             <Icon name='upload' />
                                                Choose File
                                             </Header>
                                       </label>
                                    </div>

                                    : //if reference code is generated , 
                                    <div>
                                       <label class="customFileInput cursorPointer">
                                          <input type="file" multiple={false} className="fileInput" onChange={this.onChangeFileHandler} onClick={e => (e.target.value = null)}/>
                                          <Header className='m-0' color='grey' as='h5' icon>
                                             <Icon name='upload' />
                                                Choose File
                                             </Header>
                                       </label>
                                    </div>
                              }
                           </div>

                        </Grid.Column>
                     </Grid>
                  </div>
                  :
                  <div>

                  </div>
            }
         </Segment>
      );
   }
}

export default withRouter(FileUpload);
