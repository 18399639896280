import React, { Component } from 'react'
import { BarChart as D3BarChart } from 'react-d3-components'



class BarChart extends Component {
    render() {
        var data = [{
            label: '',
            values: [...this.props.data]
        }];
        return (
            <div>
                <D3BarChart
                    data={data}
                    width={this.props.width}
                    height={this.props.height}
                    margin={{ top: 10, bottom: 50, left: 50, right: 10 }}
                />

            </div>
        )
    }
}

export default BarChart
