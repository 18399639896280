import React, { Component } from 'react'
import PdfPrev from '../../commons/PdfPreview.jsx'
import ajax from '../agent.js'
import utility from '../utility.js'
export class PdfPreview extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }
    componentDidMount() {
        this.createFileLink(this.props.refId, this.props.fileName)
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.refId !== this.props.refId || prevProps.fileName !== this.props.fileName) {
            this.createFileLink(this.props.refId, this.props.fileName)
        }
    }

    createFileLink = (dcnId, filename) => {
        let self = this
        if (dcnId && filename) {
            var promise = new Promise((resolve, reject) => {
                self.setState({ isDownloadingFile: true })
                ajax.DCNService.downloadFile(dcnId, filename).end(async (err, response) => {
                    self.setState({ isDownloadingFile: false })
                    let errJson = utility.handleErrors(err);
                    if (!err && response) {
                        let msg = [];
                        resolve(response);
                    } else {
                        utility.loadDisplayMessage(self, "error", "Failure.", errJson.errMessage);
                    }
                })
            }).then((resolve) => {
                var headers = resolve.headers;
                var blob = new Blob([resolve.xhr.response], { type: headers['content-type'] });    //{type:headers['content-type']}
                this.setState({
                    pdfFileLink: window.URL.createObjectURL(blob)
                })

            }).then((reject) => {
                //utility.loadDisplayMessage(self, "error", "Failure.", errJson.errMessage);
            }).finally(() => {
            })
        }
        else{
            this.setState({
                pdfFileLink: ''
            })
        }
    }


    render() {
        return (
            <>
                 <PdfPrev url={this.state.pdfFileLink} />
            </>
        )
    }
}

export default PdfPreview
