import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Segment, Divider, Button, Icon, Grid, Table, Header, Modal, Message } from 'semantic-ui-react';
import commonCss from '../../components/css/commonCss.js';
import ajax from '../../commons/agent.js';
import utility from '../../commons/utility.js';
import DisplayMessage from '../displayMessage.jsx';

export class FileUploadNew extends Component {
   constructor(props) {
      super(props)

      this.state = {

      }
   }

   onChangeFileHandler = (e) => {
      e.preventDefault();
      let self = this.props.context
      let finalFileList = [...this.props.context.state[this.props.name] || [], ...e.target.files]

      if (finalFileList.length > this.props.maxFilesAllowed) {
         utility.loadDisplayMessage(self, "warning", "Warning", [`Only ${this.props.maxFilesAllowed} files are allowed.`]);
      }
      else if (!this.props.fileTypesAllowedRegex.exec(e.target.files[0].name)) {
         utility.loadDisplayMessage(self, "warning", "Warning", [`Only ${this.props.fileTypesAllowed} files are allowed.`]);
      }
      else if (e.target.files[0].size / 1024 / 1024 > this.props.maxFileSize) {
         utility.loadDisplayMessage(self, "warning", "Warning", [`The selected file exceeds the max file limit of ${this.props.maxFileSize} MB.`]);
      }
      else {
         self.setState({ [this.props.name]: [...finalFileList] });
      }
   }

   removeFile = (filename) => {
      let self = this.props.context
      self.setState({ [this.props.name]: [...self.state[this.props.name].filter(file => file.name !== filename)] });

   }

   render() {
      let listOfSelectedFiles = this.props.context.state[this.props.name] || null
      return (
         <>
            <Grid columns='equal'>
               {this.props.title &&
                  <Grid.Column computer={16} verticalAlign='bottom' className='pb-0'>
                     <div className='textBold'>{this.props.title}</div>
                  </Grid.Column>
               }
               {listOfSelectedFiles && listOfSelectedFiles.length > 0 &&
                  <Grid.Column computer={16} verticalAlign='bottom' className='pb-0'>
                     {listOfSelectedFiles.map((item, key) => {
                        return (
                           <div key={key} className='px-5px text-center' style={{ display: 'inline-block' }}>
                              <Icon name='file' size='large' className='colPrimary' />
                              <p>
                                 <span title={item.name}>{utility.elipses(item.name, 16)}</span>
                                 <Icon link name='close' onClick={() => this.removeFile(item.name)} />
                              </p>
                           </div>
                        )
                     })}
                  </Grid.Column>
               }
               <Grid.Column className='py-5px'>
                  <label className="customFileInput cursorPointer">
                     <input type="file" multiple={false} className="fileInput"
                        onChange={this.onChangeFileHandler}
                        onClick={e => (e.target.value = null)}
                     />

                     <Header as='h4' className='m-0' >
                        <Icon name='upload' />
                        <Header.Content>
                           Choose File
                           <Header.Subheader>{`File types (${this.props.fileTypesAllowed}) | File Size (${this.props.maxFileSize} MB each) | Max (${this.props.maxFilesAllowed} files)`}</Header.Subheader>
                        </Header.Content>
                     </Header>
                  </label>
               </Grid.Column>

            </Grid>

         </>
      )
   }
}

export default FileUploadNew
