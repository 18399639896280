import React from 'react';
import { withTranslation } from 'react-i18next';
import { Message, Modal, Button, Segment, Header, Icon } from 'semantic-ui-react';
import utility from './utility.js'

class DisplayMessage extends React.Component {
   constructor(props) {
      super(props);
   }
   // componentDidUpdate(){
   //     if(this.props.message.length > 0){
   //         document.getElementById("messageID").scrollIntoView();
   //     }
   // }
   handleDismiss() {
      const context = this.props.context
      context.setState({ messageHeader: "", message: [] })
   }
   render() {
      self = this;
      const { t } = this.props
      var messageType = self.props.messageType;
      return (
         <div>
            {
               self.props.message.length > 0 || self.props.messageHeader !== "" ?
                  <div id="messageID">{
                     messageType === "error" ?
                        <>
                           <Modal centered open={messageType === "error"} dimmer='inverted' >
                              <Modal.Content>
                                 <Segment textAlign='center' basic className='p-0'>
                                    <Header as='h3' icon>
                                       <Icon name='close' color='red' />
                                       {self.props.messageHeader}
                                       {self.props.message.map((item, key) => <Header.Subheader key={key}>{item}</Header.Subheader>)}
                                    </Header>
                                 </Segment>
                              </Modal.Content>
                              <Modal.Actions>
                                 <Button className='customBtn1Secondary' onClick={self.handleDismiss.bind(self)}> {t('close')} </Button>
                              </Modal.Actions>
                           </Modal>
                        </>
                        :
                        messageType === "success" ?
                           <>
                              <Modal centered open={messageType === "success"} dimmer='inverted' >
                                 <Modal.Content>
                                    <Segment textAlign='center' basic className='p-0'>
                                       <Header as='h3' icon>
                                          <Icon name='check' color='green' />
                                          {self.props.messageHeader}
                                          {self.props.message.map((item, key) => <Header.Subheader key={key}>{item}</Header.Subheader>)}
                                       </Header>
                                    </Segment>
                                 </Modal.Content>
                                 <Modal.Actions>
                                    <Button className='customBtn1Secondary' onClick={self.handleDismiss.bind(self)}> {t('close')} </Button>
                                 </Modal.Actions>
                              </Modal>
                           </>
                           :
                           messageType === "info" ?
                              <Modal centered open={messageType === "info"} dimmer='inverted' >
                                 <Modal.Content>
                                    <Segment textAlign='center' basic className='p-0'>
                                       <Header as='h3' icon>
                                          <Icon name='info' color='blue' />
                                          {self.props.messageHeader}
                                          {self.props.message.map((item, key) => <Header.Subheader key={key}>{item}</Header.Subheader>)}
                                       </Header>
                                    </Segment>
                                 </Modal.Content>
                                 <Modal.Actions>
                                    <Button className='customBtn1Secondary' onClick={self.handleDismiss.bind(self)}> {t('close')} </Button>
                                 </Modal.Actions>
                              </Modal>
                              :
                              messageType === "warning" ?
                                 <>
                                    <Modal centered open={messageType === "warning"} dimmer='inverted' >
                                       <Modal.Content>
                                          <Segment textAlign='center' basic className='p-0'>
                                             <Header as='h3' icon>
                                                <Icon name='exclamation' color='yellow' />
                                                {self.props.messageHeader}
                                                {self.props.message.map((item, key) => <Header.Subheader key={key}>{item}</Header.Subheader>)}
                                             </Header>
                                          </Segment>
                                       </Modal.Content>
                                       <Modal.Actions>
                                          <Button className='customBtn1Secondary' onClick={self.handleDismiss.bind(self)}> {t('close')} </Button>
                                       </Modal.Actions>
                                    </Modal>
                                 </>
                                 : ""
                  }</div>
                  : ""
            }
         </div>
      )
   }
}

export default withTranslation()(DisplayMessage);
